<template lang="pug">
tr
  td#kind {{ file.kind || "-" }}
  td#status {{ $t(`.status.${file.status}`) || "-" }}
  td#date {{ file.createdAt || "-" }}

  td#details
    button.button-blue(@click="showModal")
      i.fa.fa-search

  file-modal(:file="file" :show="show" @close="closeModal")
</template>
<script>
import FileModal from "./file-modal.vue"

export default {
  components: { FileModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.filesTableItem",
      show: false
    }
  },

  props: {
    file: { type: Object, required: true },
  },

  methods: {
    showModal() {
      this.show = true
    },

    closeModal() {
      this.show = false
    }
  }
}
</script>
<style lang="scss">
@import "src/styles/base/tables.scss";

td {
  max-width: 50rem;
  align-items: center;

  &#kind, &#status, &#date {
    max-width: 50rem;
    align-items: center;
    min-width: fit-content;
    width: 33%;
  }

  &#details {
    min-width: unset;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    button, .button {
      width: 3.8rem;
      margin: 0;
      padding: 0;
    }

    i {
      font-size: 1.8rem;
    }
  }
}
</style>