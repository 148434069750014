<template lang="pug">
.return-page
  expansible-card(:title="$t('.files')", :expanded="true")
    files-table(
      :showingNow="files.length",
      :loading="loading",
      :pagination="pagination",
      @nextPage="(nextPage) => fetch(nextPage)"
    )
      files-table-item(
        v-for="file in files",
        :kind="file.kind",
        :status="file.status",
        :author="file.agencyName",
        :fileSize="file.fileSize",
        :fileName="file.fileName",
        :createdAt="file.createdAt",
        :downloadUrl="file.downloadUrl"
      )
</template>
<script>
import objects from "lib/objects"
import ExpansibleCard from "../../../components/expansible-card.vue"
import FilesTable from "./files-table.vue"
import FilesTableItem from "./return/files-table-item.vue"

export default {
  components: { ExpansibleCard, FilesTable, FilesTableItem },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.returnPage",

      // data
      files: [],
      prefixUrlAgency: this.$user.prefixUrlAgency,

      // fetch
      pagination: { total: '0' },
      sort: 'created_at',
      order: 'desc',
      loading: false
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    init(data, pagination) {
      this.files.push(...objects.camelize(data))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    fetch(page = null) {
      this.loading = true

      const params = { page, sort: this.sort, order: this.order }

      return this.$http
        .get(`/${this.prefixUrlAgency}/cnab-return-files.json`, { params })
        .then((response) => {
          const body = response.data
          let files = body.data
          let pagination = body.pagination || {}

          this.init(files, pagination)
        }).catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>