<template lang="pug">
Modal(v-model="isShow", :close="close")
  .info-container
    h1 {{ $t('.title') }}
    p {{ $t('.description') }}

  .buttons-container
    button.button-primary-outline(
      :class="loadingByEmail && 'button-progress'",
      :disabled="loading || loadingByEmail",
      @click="sendByEmail"
    )
      | {{ $t(".buttons.sendByEmail") }}

    button.confirm(
      :class="loading && 'button-progress'",
      :disabled="loading || loadingByEmail",
      @click="download"
    )
      | {{ $t(".buttons.download") }}
</template>
<script>
import { ref } from 'vue'
import Modal from '../../../../../components/modal.vue';

export default {
  components: { Modal },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.bankSlipModal",
      loading: false,
      loadingByEmail: false,

      options: { closeClickDimmed: true }
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    callback: { type: Function, default: () => {} },
    bill: { type: Object, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    disableDimmedClick() {
      this.options['closeClickDimmed'] = false
    },

    download() {
      this.loading = true

      this.$http.get(this.bill.bankSlipUrl, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(blob)
          link.download = this.$t('.blob.name', { id: this.bill.id })

          document.body.appendChild(link)

          link.click()

          document.body.removeChild(link)

          this.close()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.bankSlip.failure"))
        }).finally(() => {
          this.loading = false
        })
    },

    sendByEmail() {
      this.loadingByEmail = true

      this.$http.post(`/admins/bills/${this.bill.id}/bank_slip/send-email`)
        .then(() => {
          this.$notifications.info(this.$t(".notifications.email.success"))

          this.close()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.email.failure"))
        }).finally(() => {
          this.loadingByEmail = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.info-container {
  min-height: 11em;

  h1 {
    font-size: 1.7rem;
    font-weight: 550;
    color: $second-color-dark;
  }

  p {
    font-size: 1.5rem;
    color: $second-color-light;
    overflow-wrap: break-word;
    white-space: pre-line;
  }
}

.buttons-container {
  display: grid;
  justify-content: flex-end;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  width: 100%;

  button {
    width: fit-content;
    margin-bottom: 0;
  }

  .confirm {
    color: $white-color;
    background-color: $primary-color;
  }

  .close {
    color: $red-color;
    border-color: $red-color;
    background-color: $white-color;
  }

  .button-progress {
    background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
    background-size: 200%;
    animation: progress 20s ease forwards;
  }

  @keyframes progress {
    from { background-position: right; }
    to { background-position: left; }
  }
}
</style>
