import store from './store'
import http from './http'

/**
 * Singleton para controle de agências.
 *
 * @type {Object}
 */

const agency = {
  get get() {
    const agencyData = store.get('agency')

    return agencyData ? JSON.parse(agencyData) : null
  },

  async initialize() {
    if (!this.get) {
      const agencies = await this.fetch()

      if (agencies.length) setAgency(agencies[0])
    }
  },

  async set(id) {
    const agency = await this.fetchById(id)

    if (agency) setAgency(agency)
  },

  async fetch() {
    try {
      const { data } = await http.get(`real_estate/users/agencies`)

      return data.data
    } catch {
      return []
    }
  },

  async fetchById(id) {
    try {
      const { data } = await http.get(`real_estate/users/agencies/${id}`)

      return data
    } catch {
      return null
    }
  }
}

const setAgency = ({ id, name, contracts_payment_type }) => {
  store.set('agency', JSON.stringify({ id, name, contracts_payment_type }))
}

export default agency
