<template lang="pug">
.remittance-page
  expansible-card(:title="$t('.titles.submit')", :expanded="true")
    form(ref="form", @submit.prevent="submit", novalidate)
      .fields
        file-field(
          type="file"
          name="file"
          accept=".rem"
          :label="$t('.label.file')"
          :error="errors?.file?.message"
          :reset="resetFile"
          @reseted="resetFile = false"
          required
          destroyable
        )

      button.button-primary.u-full-width(
        type="submit",
        :class="submitting && 'button-progress'",
        :disabled="submitting"
      )
        | {{ $t(".button.submit") }}

  expansible-card(:title="$t('.titles.uploadedFiles')", :expanded="true")
    files-table(
      :showingNow="files.length",
      :loading="loading",
      :pagination="pagination",
      @nextPage="(nextPage) => fetch(nextPage)"
    )
      files-table-item(
        v-for="file in files"
        :file="file"
      )
</template>
<script>
import objects from "lib/objects"
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "../navbar.vue"
import ExpansibleCard from "../../../components/expansible-card.vue"
import FilesTable from "./files-table.vue"
import FilesTableItem from "./remittance/files-table-item.vue"

export default {
  mixins: [vueAuthenticate],

  components: {
    Navbar, ExpansibleCard, FilesTable, FilesTableItem
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.remittancePage",

      // form
      realEstateAgency: null,
      resetFile: false,

      // submit
      errors: {},
      submitting: false,

      // data
      files: [],
      prefixUrlAgency: this.$user.prefixUrlAgency,

      // fetch
      pagination: { total: '0' },
      sort: 'created_at',
      order: 'desc',
      loading: false
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    init(data, pagination) {
      this.files.push(...objects.camelize(data))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    fetch(page = null) {
      this.loading = true

      const params = { page, sort: this.sort, order: this.order }

      return this.$http
        .get(`/${this.prefixUrlAgency}/cnab-files.json`, { params })
        .then((response) => {
          const body = response.data
          let files = body.data
          let pagination = body.pagination || {}

          this.init(files, pagination)
        }).catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        }).finally(() => {
          this.loading = false
        })
    },

    submit() {
      if (this.validateFields()) {
        this.submitting = true

        const formData = new FormData(this.$refs.form)
        const config = { headers: { "Content-Type": "multipart/form-data" } }

        this.$http
          .post(`/${this.prefixUrlAgency}/cnab-files`, formData, config)
          .then(() => {
            this.$notifications.clear()
            this.$notifications.info(this.$t(".notifications.submit.success"))

            this.realEstateAgency = null
            this.resetFile = true

            this.submitting = false

            this.files = []
            this.fetch()
          }).catch((err) => {
            this.$notifications.error(this.$t(".notifications.submit.failure"))
            this.submitting = false

            this.errors = objects.camelize(
              objects.dig(err, "response", "data", "errors") || {}
            )
          })
      }
    },

    validateFields() {
      this.errors = {}

      const form = this.$refs.form

      if (form.checkValidity()) {
        return true
      }

      const invalidItems = form.querySelectorAll(':invalid')

      invalidItems.forEach(item => {
        this.errors[item.name] = this.$t(`.notifications.validate.${item.name}`)
      })

      this.errors = objects.camelize(this.errors)

      return false
    }
  }
}
</script>
<style lang="scss">
.button-progress {
  background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
  background-size: 200%;
  animation: progress 5s ease forwards;
}

@keyframes progress {
  from { background-position: right; }
	to { background-position: left; }
}

.fields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;

  > * {
    flex: 1;
  }
}
</style>